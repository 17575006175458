export default function Loading() {
  return (
    <div className="container-fluid ">
      <div className="row justify-content-center py-5">
        <div className="col-auto py-5">
          <div className="spinner-border text-info my-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}
