import Template from "../../../Template";

export default function Interview(props) {
  return (
    <Template title="Interview">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header bg-zad-blue">
                <h3 className="card-title">
                  <i className="fa-solid fa-circle-info mr-2" />
                  Informasi
                </h3>
              </div>
              <div className="card-body">
                <h3
                  className="text-center text-zad-blue"
                  style={{ fontSize: "2.5em" }}
                >
                  <i className="fa-solid fa-circle-info" />
                </h3>
                <h4 className="text-center text-bold">Informasi Interview</h4>
                <h5 className="text-center">
                  Informasi Interview akan Diinformasikan Lebih Lanjut
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
  // return (
  //   <Template title="Interview" page={Pages.Admission.Interview}>
  //     <div className="container-fluid">
  //       <div className="row">
  //         <div className="col">
  //           <div class="callout callout-info">
  //             <h5>
  //               <i className="fa-solid fa-circle-info mr-2" />I am an info
  //               callout!
  //             </h5>
  //             <p>Follow the steps to continue to payment.</p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="row">
  //         <div className="col">
  //           <div className="card card-info">
  //             <div className="card-header">
  //               <h3 className="card-title">Interview Information</h3>
  //             </div>
  //             <div className="card-body table-responsive p-0">
  //               <table className="table table-hover text-nowrap">
  //                 <thead>
  //                   <tr>
  //                     <th>Information</th>
  //                     <th>Detail</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   <tr>
  //                     <td>Interviewer</td>
  //                     <td>Ferina Harce, S.T.</td>
  //                   </tr>
  //                   <tr>
  //                     <td>Interviewer Contact</td>
  //                     <td>
  //                       <a href="https://whatsapp.me/group">
  //                         6281234567890 (WhatsApp)
  //                       </a>
  //                     </td>
  //                   </tr>
  //                   <tr>
  //                     <td>Time</td>
  //                     <td>Sabtu, 15 November 2023 10:00 WIB</td>
  //                   </tr>
  //                   <tr>
  //                     <td>Google Meet</td>
  //                     <td>
  //                       <a href="https://google.classroom/link/kelas">
  //                         https://meet.google.com/link/kelas
  //                       </a>
  //                     </td>
  //                   </tr>
  //                 </tbody>
  //               </table>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </Template>
  // );
}
