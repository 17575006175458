import React from "react";
import { useEffect, useState } from "react";
import Template from "../../../Template";
import Loading from "../../../Components/Loading";
import axios from "axios";
import Swal from "sweetalert2";

export default function Form(props) {
  const [loading, setLoading] = useState(true);
  const [studentError, setStudentError] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const getAdmissionQuestionsPromise = axios
      .get("/admission/assessment/question")
      .then((response) => {
        const questions = response.data;
        const categories = [...new Set(questions.map((q) => q.category))];

        const categorizedQuestions = categories.map((category) => {
          return questions.filter((q) => q.category === category);
        });

        setQuestions(categorizedQuestions);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setStudentError(true);
        }
      });

    const getAdmissionAnswersPromise = axios
      .get("/admission/assessment")
      .then((response) => {
        const answers = response.data;
        const parsedAnswers = answers.map((answer) => {
          return {
            question_id: answer.student_assessment_question_id,
            answer: answer.answer,
          };
        });

        setAnswers(parsedAnswers);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setStudentError(true);
        }
      });

    Promise.allSettled([
      getAdmissionQuestionsPromise,
      getAdmissionAnswersPromise,
    ]).finally(() => {
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    axios.post("/admission/assessment/bulk", answers).then((response) => {
      if (response.status === 201)
        Swal.fire({
          title: "Your assessment has been saved",
          text: "Please proceed to admission fee invoice page",
          icon: "success",
        });
    });
  };

  const handleTextboxAndRadioChange = (e) => {
    const questionId = e.target.dataset.questionId;
    const filledAnswer = e.target.value;

    const answerIndex = answers.findIndex(
      (val) => val.question_id == questionId
    );

    const answersToBeChanged = answers.map((i) => i);

    if (answerIndex >= 0) answersToBeChanged[answerIndex].answer = filledAnswer;
    else
      answersToBeChanged.push({
        question_id: parseInt(questionId),
        answer: filledAnswer,
      });

    setAnswers(answersToBeChanged);
  };

  const handleCheckboxChange = (e) => {
    // Form inputs
    const questionId = e.target.dataset.questionId;
    const filledAnswer = e.target.value;
    const checkboxChecked = e.target.checked;

    // Copy answers from react state to new variable
    const answersToBeChanged = answers.map((i) => i);

    // Find the currently editing questions answer by question id
    const answerIndex = answers.findIndex(
      (val) => val.question_id == questionId
    );

    // Parse answer from string to JSON
    let answerArr = [];
    if (answerIndex >= 0)
      answerArr = JSON.parse(answersToBeChanged[answerIndex].answer);

    // Handle checkbox update when checked/unchecked
    if (checkboxChecked) answerArr.push(filledAnswer);
    else answerArr = answerArr.filter((val) => val !== filledAnswer);

    if (answerIndex >= 0) {
      // Update to existing data
      answersToBeChanged[answerIndex].answer = JSON.stringify(answerArr);
    } else {
      // Create new data (new answer record)
      answersToBeChanged.push({
        question_id: parseInt(questionId),
        answer: JSON.stringify(answerArr),
      });
    }

    // Set to state
    setAnswers(answersToBeChanged);
  };

  const handleOtherCheckboxChange = (e) => {
    // Form inputs
    const questionId = e.target.dataset.questionId;
    const filledAnswer = e.target.value;
    const questionsOptions = e.target.dataset.options;

    // Copy answers from react state to new variable
    const answersToBeChanged = answers.map((i) => i);

    // Find the currently editing questions answer by question id
    const answerIndex = answers.findIndex(
      (val) => val.question_id == questionId
    );

    // Parse answer from string to JSON
    let answerArr = [];
    if (answerIndex >= 0)
      answerArr = JSON.parse(answersToBeChanged[answerIndex].answer);

    // Get answers that already checked (?)
    var filteredAnswerArr = answerArr.filter((val) =>
      JSON.parse(questionsOptions).includes(val)
    );

    // Add custom text value from textbox to answer array
    filteredAnswerArr.push(filledAnswer);

    if (answerIndex >= 0) {
      // Update to existing data
      answersToBeChanged[answerIndex].answer =
        JSON.stringify(filteredAnswerArr);
    } else {
      // Create new data (new answer record)
      answersToBeChanged.push({
        question_id: parseInt(questionId),
        answer: JSON.stringify(filteredAnswerArr),
      });
    }

    // Set to state
    setAnswers(answersToBeChanged);
  };

  if (loading)
    return (
      <Template title="Student Assessment Form">
        <Loading />
      </Template>
    );

  if (studentError)
    return (
      <Template title="Student Assessment Form">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header bg-zad-red">
                  <h3 className="card-title">Informasi</h3>
                </div>
                <div className="card-body">
                  <h3
                    className="text-center text-zad-red"
                    style={{ fontSize: "2.5em" }}
                  >
                    <i className="fa-solid fa-circle-exclamation" />
                  </h3>
                  <h4 className="text-center text-bold">
                    Anda belum mengisi student profile
                  </h4>
                  <h5 className="text-center">
                    Silahkan lakukan pengisian student profile terlebih dahulu
                    untuk mengisi assessment form
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
    );

  return (
    <Template title="Student Assessment Form">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <div className="callout callout-zad-blue">
              <h5 style={{ fontWeight: 600 }}>
                <i className="fa-solid fa-circle-info mr-2" />
                Pengisian <i>Student Assessment</i>
              </h5>
              <p>
                Silahkan isi <i>assessment</i> di bawah ini dengan{" "}
                <b>sebenar-benarnya</b> sesuai dengan kondisi di lingkungan
                ananda. Harap semua pertanyaan diisi, jika tidak maka silahkan
                isi dengan strip (-)
                <br />
                <br />
                Jawaban anda bersifat rahasia dan tidak akan dibagikan kepada
                pihak manapun selain guru dan admin sekolah.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-6">
            {questions.map((questionCategory, index) => (
              <div className="card" key={index}>
                <div className="card-header bg-zad-blue"></div>
                <div className="card-body">
                  {questionCategory.map((question, index) => (
                    <div className="form-group px-2" key={index}>
                      <label
                        htmlFor={`input-c${questionCategory.id}-q${question.id}`}
                        style={{ fontSize: "1.4em" }}
                        className="m-0"
                      >
                        {question.question_ind}{" "}
                        {!answers.find((val) => val.question_id == question.id)
                          ?.answer ||
                        answers.find((val) => val.question_id == question.id)
                          ?.answer === "[]" ||
                        answers.find((val) => val.question_id == question.id)
                          ?.answer === "" ? (
                          <i className="fa-solid fa-circle-exclamation text-danger" />
                        ) : null}
                      </label>
                      <br />
                      <small>{question.description}</small>

                      {question.type === "text" ? (
                        <textarea
                          className="form-control mt-3"
                          id={`input-c${questionCategory.id}-q${question.id}`}
                          placeholder="Enter Answer"
                          rows={4}
                          data-question-id={question.id}
                          value={
                            answers.find(
                              (val) => val.question_id == question.id
                            )?.answer
                          }
                          onChange={handleTextboxAndRadioChange}
                        />
                      ) : null}

                      {question.type === "radio" ? (
                        <div className="form-group mt-3">
                          {JSON.parse(question.options).map((option, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`question-c${questionCategory.id}-q${question.id}"`}
                                data-question-id={question.id}
                                value={option}
                                onChange={handleTextboxAndRadioChange}
                                checked={
                                  answers.find(
                                    (val) => val.question_id == question.id
                                  )?.answer === option
                                }
                              />
                              <label className="form-check-label">
                                {option}
                              </label>
                            </div>
                          ))}

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`question-c${questionCategory.id}-q${question.id}"`}
                              data-question-id={question.id}
                              value=""
                              onChange={handleTextboxAndRadioChange}
                              defaultChecked={
                                !JSON.parse(question.options).includes(
                                  answers.find(
                                    (val) => val.question_id == question.id
                                  )?.answer
                                ) &&
                                answers.find(
                                  (val) => val.question_id == question.id
                                )
                              }
                            />
                            <label className="form-check-label">
                              Other/Lainnya...
                            </label>
                            {!JSON.parse(question.options).includes(
                              answers.find(
                                (val) => val.question_id == question.id
                              )?.answer
                            ) &&
                            answers.find(
                              (val) => val.question_id == question.id
                            ) ? (
                              <input
                                className="form-control form-control-sm w-50"
                                type="text"
                                placeholder="Enter unlisted answer here"
                                data-question-id={question.id}
                                onChange={handleTextboxAndRadioChange}
                                value={
                                  answers.find(
                                    (val) => val.question_id == question.id
                                  )?.answer
                                }
                              />
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {question.type === "checkbox" ? (
                        <div className="form-group mt-2">
                          {JSON.parse(question.options).map((option, index) => (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                data-question-id={question.id}
                                value={option}
                                onChange={handleCheckboxChange}
                                checked={JSON.parse(
                                  answers.find(
                                    (val) => val.question_id == question.id
                                  )?.answer ?? "[]"
                                ).includes(option)}
                              />
                              <label className="form-check-label">
                                {option}
                              </label>
                            </div>
                          ))}
                          <div className="form-group mt-2">
                            <label
                              htmlFor={`question-c${questionCategory.id}-q${question.id}"`}
                            >
                              Other Answer/Jawaban Lainnya...
                            </label>
                            <textarea
                              id={`question-c${questionCategory.id}-q${question.id}"`}
                              className="form-control form-control-sm w-75"
                              type="text"
                              data-question-id={question.id}
                              data-options={question.options}
                              placeholder="Enter unlisted answer here"
                              rows={2}
                              value={JSON.parse(
                                answers.find(
                                  (val) => val.question_id == question.id
                                )?.answer ?? "[]"
                              ).filter(
                                (val) =>
                                  !JSON.parse(question.options).includes(val)
                              )}
                              onChange={handleOtherCheckboxChange}
                            />
                          </div>
                        </div>
                      ) : null}

                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row justify-content-center pb-5">
          <div className="col-md-10 col-lg-8 col-xl-6">
            <button
              className="btn bg-zad-green py-2 w-100"
              onClick={handleSubmit}
            >
              <i className="fa-solid fa-save mr-2" />
              Save
            </button>
          </div>
        </div>
      </div>
    </Template>
  );
}
