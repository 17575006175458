import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSearchParams, Navigate } from "react-router-dom";

import { UserContext } from "../../Contexts/UserContext";

export default function GoogleLogin(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  let [redirect, setRedirect] = useState(null);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    axios
      .get(`/auth/callback?${searchParams.toString()}`)
      .then((response) => {
        let login = response.data;
        localStorage.setItem("access_token", login.access_token);
        localStorage.setItem("name", login.user.name);
        setUser({ ...user, name: login.user.name });

        setRedirect("/student/admission/dashboard");
      })
      .catch((error) => {
        console.log(error.response);
        setRedirect("/login");
      });
  }, []);

  return redirect ? (
    <Navigate to={redirect} />
  ) : (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-3">
          <h3>You will be redirected in a moment...</h3>
        </div>
      </div>
    </div>
  );
}
