import "./index.css";

import ZadLogo from "../../Assets/login-logo.png";
import LoginBackground from "../../Assets/login-bg.jpg";
import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

import Swal from "sweetalert2";

import { UserContext } from "../../Contexts/UserContext";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [redirect, setRedirect] = useState(null);

  const { user, setUser } = useContext(UserContext);

  const handleLogin = (e) => {
    e.preventDefault();

    axios
      .post("/auth/login", {
        email,
        password,
      })
      .then((response) => {
        const login = response.data;
        localStorage.setItem("access_token", login.access_token);
        localStorage.setItem("name", login.user.name);
        setUser({ ...user, name: login.user.name });

        setRedirect("/student/admission/dashboard");
      })
      .catch((e) => {
        console.log(e.response);
        Swal.fire("Login Failed", "Incorrect email or password.", "error");
      });
  };

  if (redirect != null) return <Navigate to={redirect} replace={true} />;

  return (
    <div
      className="container-fluid px-md-5 login-body"
      style={{ backgroundImage: `url(${LoginBackground})` }}
    >
      <div className="row" style={{ minHeight: "100vh" }}>
        <div className="col-lg-7 col-md-6 px-md-5 px-4 pt-5 pt-sm-0 mx-auto my-auto">
          <img
            src={ZadLogo}
            alt="Zad Logo"
            style={{ width: "160px", height: "90px" }}
          />
          <h1 className="login-info-title" style={{ lineHeight: "30px" }}>
            Academic Year 24/25 Admission Portal
          </h1>
          <p className="login-info-content">
            <span>
              Selamat datang di portal PPDB Zad Islamic Playschool Tahun Ajaran
              24/25. <br />
              <br />
              <span>احرص على ما ينفعك ، واستعن بالله</span>
              <br />
              "Bersemangatlah dalam hal yang bermanfaat bagimu! Mintalah
              pertolongan kepada Allah!“
              <br />
              <br />
            </span>
            <span>بارك الله فيكم</span>
            <br />
            <br />
            <i className="fa-brands fa-whatsapp" /> Teknis Pendaftaran{" "}
            <a
              href="https://wa.me/6289513142813"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              6289513142813
            </a>
            <br />
          </p>
        </div>
        <div className="col-lg-3 col-md-6 px-3 px-md-0 py-3 mx-auto my-auto">
          <div className="card login-box w-100">
            <div className="card-body">
              <h4 style={{ textAlign: "center" }}>Portal Login</h4>
              <hr />
              <form>
                <div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-envelope" />
                      </span>
                    </div>
                    <input
                      autoFocus
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>

                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-key" />
                      </span>
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block bg-zad-blue"
                    onClick={handleLogin}
                  >
                    <i className="fa-solid fa-right-to-bracket" /> Login
                  </button>
                </div>
              </form>

              <button
                type="submit"
                className="btn bg-zad-red btn-block mt-2"
                onClick={() =>
                  window.location.replace(
                    process.env.REACT_APP_GOOGLE_AUTH_REDIRECT
                  )
                }
              >
                <i className="fa-brands fa-google" /> Login With Google
              </button>

              <hr />

              <p className="text-center">Do not have an account?</p>
              <button
                type="submit"
                className="btn btn-light btn-block mt-2"
                onClick={() =>
                  window.location.replace(
                    process.env.REACT_APP_GOOGLE_AUTH_REDIRECT
                  )
                }
              >
                <i className="fa-brands fa-google" /> Sign Up With Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
