import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import ZadLogo from "../../Assets/logo-shadowed.png";
import UserClipArt from "../../Assets/user.png";

import { UserContext } from "../../Contexts/UserContext";

export default function Sidebar() {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    $(".sidebar-toggle-btn").PushMenu();
    $("ul").Treeview();
  }, [user]);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <NavLink to="/student/admission/dashboard" className="brand-link">
        <img
          src={ZadLogo}
          alt="Zadulilmi Logo"
          className="brand-image"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">Student Portal</span>
      </NavLink>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={
                user.profilePicture
                  ? `data:;base64,${user.profilePicture}`
                  : UserClipArt
              }
              className="img-circle elevation-2"
              alt="User"
            />
          </div>
          <div className="info">
            <NavLink to="/student/admission/dashboard" className="d-block">
              {user.name}
            </NavLink>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item sidebar-toggle-btn">
              <NavLink
                to="/student/admission/dashboard"
                className="nav-link nav-link-zad"
              >
                <i className="nav-icon fa-solid fa-gauge-high" />
                <p>Dashboard</p>
              </NavLink>
            </li>

            <li className="nav-header">Admission</li>
            <li className="nav-item sidebar-toggle-btn">
              <NavLink
                to="/student/admission/profile"
                className="nav-link nav-link-zad"
              >
                <i className="nav-icon fa-solid fa-user-graduate" />
                <p>Student Profile</p>
              </NavLink>
            </li>
            <li className="nav-item sidebar-toggle-btn">
              <NavLink
                to="/student/admission/assessment"
                className="nav-link nav-link-zad"
              >
                <i className="nav-icon fa-solid fa-user-pen" />
                <p>Assessment Form</p>
              </NavLink>
            </li>
            <li className="nav-item sidebar-toggle-btn">
              <NavLink
                to="/student/admission/payment"
                className="nav-link nav-link-zad"
              >
                <i className="nav-icon fa-solid fa-credit-card" />
                <p>Admission Fee</p>
              </NavLink>
            </li>
            <li className="nav-item sidebar-toggle-btn">
              <NavLink
                to="/student/admission/document"
                className="nav-link nav-link-zad"
              >
                <i className="nav-icon fa-solid fa-file" />
                <p>Documents</p>
              </NavLink>
            </li>
            <li className="nav-item sidebar-toggle-btn">
              <NavLink
                to="/student/admission/interview"
                className="nav-link nav-link-zad"
              >
                <i className="nav-icon fa-solid fa-user-group" />
                <p>Interview</p>
              </NavLink>
            </li>
            <li className="nav-item sidebar-toggle-btn">
              <NavLink
                to="/student/admission/class"
                className="nav-link nav-link-zad"
              >
                <i className="nav-icon fa-solid fa-circle-info" />
                <p>Class Information</p>
              </NavLink>
            </li>
            {/* <li className="nav-item sidebar-toggle-btn">
              <a href="/" className="nav-link">
                <i className="nav-icon far fa-envelope" />
                <p>
                  Mailbox
                  <i className="fas fa-angle-left right" />
                </p>
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
