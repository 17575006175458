import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function CheckIcon(props) {
  if (props.value === true)
    return <i className="bg-zad-green fa-solid fa-check p-1" />;
  else return <i className="bg-zad-red fa-solid fa-minus p-1" />;
}

export default function AdmissionProgress(props) {
  const [progress, setProgress] = useState(0);
  const [detailedProgress, setDetailedProgress] = useState([
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    let progressCount = 0;

    let currentProgress = props.data.progress;
    let currentDetailedProgress = [false, false, false, false, false];

    if (currentProgress.user) {
      currentDetailedProgress[0] = true;
      progressCount += 10;
    }

    if (currentProgress.student) {
      currentDetailedProgress[1] = true;
      progressCount += 10;
    }

    if (currentProgress.assessment) {
      currentDetailedProgress[2] = true;
      progressCount += 20;
    }

    if (currentProgress.payment) {
      currentDetailedProgress[3] = true;
      progressCount += 40;
    }

    if (currentProgress.documents) {
      currentDetailedProgress[4] = true;
      progressCount += 10;
    }

    setProgress(progressCount);
    setDetailedProgress(currentDetailedProgress);
  }, [props.data.progress]);

  const colMaxWidth = 70;

  return (
    <>
      {/* detailedProgress[3]  */}
      {detailedProgress[3] ? (
        <div className="callout callout-success bg-zad-green">
          <h5>
            <i class="fa-solid fa-user-check mr-2"></i>
            <strong>
              Selamat, sampai tahap ini Ananda telah secara resmi diterima di
              Zad Islamic Playschool
            </strong>
          </h5>
          <p>
            Silahkan melakukan langkah berikutnya yaitu <i>upload</i>{" "}
            <i className="fa-solid fa-file ml-1" /> <b>dokumen</b> (paling
            lambat 30 hari setelah pendaftaran diterima) kemudian menunggu
            jadwal{" "}
            <i>
              interview (undangan akan dikirimkan melalui email & bisa dicek
              melalui admission dashboard).
            </i>
            <br />
            بارك الله فيكم
          </p>
        </div>
      ) : null}

      <div className="card">
        <div className="card-header bg-zad-blue">
          <h3 className="card-title">
            <i className="fa-solid fa-bars-progress mr-2" />
            Student Admission Progress
          </h3>
        </div>

        <div className="card-body">
          <h4 className="text-bold text-zad-red">
            <i className="fa-solid fa-user-graduate mr-2" />
            Student Information
          </h4>
          <table className="table table-sm table-bordered">
            <tbody>
              <tr>
                <td style={{ maxWidth: colMaxWidth }}>Student Name</td>
                <td style={{ fontWeight: 600 }}>{props.data.user.name}</td>
              </tr>
              <tr>
                <td style={{ maxWidth: colMaxWidth }}>Father Name</td>
                <td style={{ fontWeight: 600 }}>
                  {props.data.user.student?.father_name}
                </td>
              </tr>
              <tr>
                <td style={{ maxWidth: colMaxWidth }}>Mother Name</td>
                <td style={{ fontWeight: 600 }}>
                  {props.data.user.student?.mother_name}
                </td>
              </tr>
              <tr>
                <td style={{ maxWidth: colMaxWidth }}>Phone Number</td>
                <td style={{ fontWeight: 600 }}>{props.data.user.phone}</td>
              </tr>
              <tr>
                <td style={{ maxWidth: colMaxWidth }}>Register to Branch</td>
                <td style={{ fontWeight: 600 }}>
                  {props.data.user.student?.branch}
                </td>
              </tr>
              <tr>
                <td style={{ maxWidth: colMaxWidth }}>Level</td>
                <td style={{ fontWeight: 600 }}>
                  {props.data.user.student?.level}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="elevation-1 my-3 px-3 py-1 rounded">
            <h4 className="text-center mt-4 mb-2 text-bold  text-zad-red">
              Your Admission Progress
            </h4>
            <div className="progress">
              <div
                className="progress-bar bg-zad-green"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <p className="text-center" style={{ fontSize: "1.4em" }}>
              <span className="badge bg-zad-green">{progress} %</span>
            </p>
          </div>

          <div className="table-responsive elevation-1">
            <table className="table table-bordered table-hover text-nowrap">
              <thead>
                <tr>
                  <th className="text-center">Status</th>
                  <th className="text-center">Tahapan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <CheckIcon value={detailedProgress[0]} />
                  </td>
                  <td>
                    Mengisi{" "}
                    <Link to="/student/admission/profile">
                      <i className="nav-icon fa-solid fa-user-graduate mx-1" />
                      Student Profile
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <CheckIcon value={detailedProgress[1]} />
                  </td>
                  <td>
                    Memilih Cabang dan Mengisi Nama Orang Tua pada{" "}
                    <Link className="" to="/student/admission/profile">
                      <i className="nav-icon fa-solid fa-user-graduate mx-1" />
                      Student Profile
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <CheckIcon value={detailedProgress[2]} />
                  </td>
                  <td>
                    Mengisi{" "}
                    <Link to="/student/admission/assessment">
                      <i className="nav-icon fa-solid fa-user-pen mx-1" />{" "}
                      Assessment Form
                    </Link>{" "}
                    ({props.data.assessment.answered}/
                    {props.data.assessment.total === 99
                      ? "-"
                      : props.data.assessment.total}
                    )
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <CheckIcon value={detailedProgress[3]} />
                  </td>
                  <td>
                    Melakukan Pembayaran{" "}
                    <Link to="/student/admission/payment">
                      <i className="nav-icon fa-solid fa-credit-card mx-1" />{" "}
                      Admission Fee
                    </Link>{" "}
                    <span className="text-zad-green text-bold">
                      (Officially Accepted)
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <CheckIcon value={detailedProgress[4]} />
                  </td>
                  <td>
                    Mengunggah{" "}
                    <Link to="/student/admission/document">
                      <i className="nav-icon fa-solid fa-file mx-1" />
                      Dokumen Murid
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <CheckIcon value={detailedProgress[5]} />
                  </td>
                  <td>
                    Melakukan{" "}
                    <Link to="/student/admission/interview">
                      <i className="nav-icon fa-solid fa-user-group mx-1" />
                      Interview
                    </Link>{" "}
                    Orang Tua Siswa
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
