import Template from "../../../Template";

import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "../../../Components/Loading";
import AdmissionProgress from "../../../Components/AdmissionProgress";

export default function Payment(props) {
  const [loading, setLoading] = useState(true);
  const [createPaymentLoading, setCreatePaymentLoading] = useState(false);

  const [admissionPayment, setAdmissionPayment] = useState({
    registration_fee: null,
  });

  const [incompleteFlowError, setIncompleteFlowError] = useState(false);
  const [admissionProgressData, setAdmissionProgressData] = useState();
  const [paymentTimeLimit, setPaymentTimeLimit] = useState(new Date());

  useEffect(() => {
    const getAdmissionProgressPromise = axios
      .get("/admission/progress")
      .then((response) => {
        let currentProgress = response.data.progress;

        if (
          !currentProgress.user ||
          !currentProgress.student ||
          !currentProgress.assessment
        )
          setIncompleteFlowError(true);

        setAdmissionProgressData(response.data);
      })
      .catch(() => {});

    const getAdmissionPaymentStatusPromise = getAdmissionPaymentStatus();

    Promise.allSettled([
      getAdmissionProgressPromise,
      getAdmissionPaymentStatusPromise,
    ]).finally(() => setLoading(false));
  }, []);

  const handleCreateInvoice = () => {
    setCreatePaymentLoading(true);
    axios
      .post("/admission/payment")
      .then(() => {
        Swal.fire(
          "Pembuatan Invoice",
          "Invoice anda telah selesai dibuat.",
          "success"
        );

        getAdmissionPaymentStatus();
      })
      .catch((error) => {
        if (error.response.status === 403)
          Swal.fire(
            "Waiting List",
            "Pembayaran belum bisa diproses karena status Anda saat ini waiting list.",
            "error"
          );
      })
      .finally(() => setCreatePaymentLoading(false));
  };

  const handleDeleteInvoice = () => {
    Swal.fire({
      title: "Apakah anda ingin melakukan pembatalan invoice?",
      icon: "question",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete("/admission/payment").then((response) => {
          Swal.fire({
            title: "Invoice anda telah berhasil dibatalkan.",
            icon: "success",
          });
          getAdmissionPaymentStatus();
        });
      }
    });
  };

  const getAdmissionPaymentStatus = () => {
    return axios
      .get("/admission/payment")
      .then((response) => {
        setAdmissionPayment(response.data);
        console.table();
        let timeLimit = new Date(response.data.registration_fee?.created_at);
        if (response.data.user.student.branch === "ONLINE CLASS")
          timeLimit.setDate(timeLimit.getDate() + 1);
        else timeLimit.setHours(timeLimit.getHours() + 1);

        setPaymentTimeLimit(timeLimit);
      })
      .catch(() => setIncompleteFlowError(true));
  };

  const handlePriceDown = () => {
    Swal.fire({
      icon: "info",
      title: "Pengajuan Keringanan Admission Fee",
      html: `Silahkan download, print, dan isi <a href="https://drive.google.com/file/d/1PNTEHLl4eVhRx_SppCpbuYCf4c6qK4wC/view?usp=sharing" target="_blank">surat ini</a>. Kemudian kirimkan ke admin <a href="mailto:zad.playschool@gmail.com>zad.playschool@gmail.com</a> dan konfirmasi ke admin <a target="blank" rel="noopener noreferrer" href="https://wa.me/6289513142813">6289513142813</a>`,
      footer: "Silahkan tanyakan kepada admin untuk informasi lebih lanjut",
    });
  };

  if (loading)
    return (
      <Template title="Admission Fee">
        <Loading />
      </Template>
    );

  if (incompleteFlowError)
    return (
      <Template title="Admission Fee">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <AdmissionProgress data={admissionProgressData} />
            </div>
            <div className="col">
              <div className="card">
                <div className="card-header bg-zad-red">
                  <h3 className="card-title">Informasi</h3>
                </div>
                <div className="card-body">
                  <h3
                    className="text-center text-zad-red"
                    style={{ fontSize: "2.5em" }}
                  >
                    <i className="fa-solid fa-circle-exclamation" />
                  </h3>
                  <h4 className="text-center text-bold">
                    Anda belum bisa melakukan pembayaran
                  </h4>
                  <h5 className="text-center">
                    Silahkan cek kembali kelengkapan student profile dan student
                    assessment form
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
    );

  const colMaxWidth = 50;

  return (
    <Template title="Admission Fee">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header bg-zad-blue">
                <h3 className="card-title">
                  <i className="fa-solid fa-file-invoice-dollar mr-2" />
                  Invoice Status
                </h3>
              </div>
              <div className="card-body">
                <h5 className="text-zad-green text-bold">
                  <i className="fa-solid fa-file-invoice-dollar mr-2" />
                  Admission Fee Information
                </h5>
                <table className="table table-sm table-bordered mb-3">
                  <tbody>
                    <tr>
                      <td style={{ maxWidth: colMaxWidth }}>Student Name</td>
                      <td style={{ fontWeight: 600 }}>
                        {admissionPayment.user.name}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ maxWidth: colMaxWidth }}>Branch/Program</td>
                      <td style={{ fontWeight: 600 }}>
                        {admissionPayment.user.student?.branch}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ maxWidth: colMaxWidth }}>Level/Jenjang</td>
                      <td style={{ fontWeight: 600 }}>
                        {admissionPayment.user.student?.level}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ maxWidth: colMaxWidth }}>Admission Fee</td>
                      <td style={{ fontWeight: 600 }}>
                        Rp
                        {admissionPayment.registration_fee_amount.toLocaleString(
                          "ID-id"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4 className="text-center text-bold">Invoice Status</h4>
                <h2 className="text-center">
                  {admissionPayment.registration_fee == null ? (
                    <>
                      <span className="badge bg-zad-red">
                        Invoice Belum Dibuat
                      </span>
                    </>
                  ) : admissionPayment.registration_fee?.status ===
                    "settlement" ? (
                    <span className="badge bg-zad-green">Telah Dibayar</span>
                  ) : (
                    <span className="badge bg-zad-orange">Belum Dibayar</span>
                  )}
                </h2>
              </div>
              <div className="card-footer">
                <small className="text-muted">
                  If you ecounter a problem, please contact
                  <a
                    href="https://wa.me/6289513142813"
                    target="_blank"
                    rel="noreferrer nofollow"
                    className="ml-1"
                  >
                    <i className="fa-brands fa-whatsapp" /> 6289513142813
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>

        {admissionPayment.registration_fee == null ? (
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header bg-zad-blue">
                  <h3 className="card-title">Create Invoice</h3>
                </div>
                <div className="card-body">
                  {/* <div className="callout callout-zad-red">
                    <h5 style={{ fontSize: "1.1em", fontWeight: 600 }}>
                      <i className="fa-solid fa-circle-info mr-2" />
                      Informasi Penguncian Data
                    </h5>
                    <small>
                      Setelah tagihan dibuat, anda <b>tidak dapat</b> melakukan
                      perubahan <i>student profile</i>, <i>branch/program</i>,
                      jenjang yang dituju, dan <i>student assessment form</i>.
                      <br />
                      Silahkan pastikan kembali data yang diisi telah benar dan
                      lengkap sebelum membuat invoice.
                    </small>
                  </div> */}

                  <p>
                    Segera lakukan pembayaran <i>admission fee</i> karena kuota
                    pada branch/program dan jenjang yang dipilih berpotensi{" "}
                    <b>penuh atau didahului pendaftar lain</b>.
                  </p>
                  <p>
                    Bagi pendaftar yang memiliki adik atau kakak siswa aktif Zad
                    Islamic Playschool atau mendaftarkan adik dan kakak secara
                    bersamaan, silahkan menghubungi admin{" "}
                    <a
                      href="https://wa.me/6289513142813"
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <i className="fa-brands fa-whatsapp" /> 6289513142813
                    </a>{" "}
                    untuk mekanisme diskon.
                  </p>

                  <button
                    type="button"
                    className="btn btn-block btn-lg bg-zad-green"
                    onClick={handleCreateInvoice}
                    disabled={createPaymentLoading}
                  >
                    <i className="fa-solid fa-scroll mr-2" />
                    Lakukan Pembayaran
                  </button>

                  <small className="mt-3 text-muted">
                    Jika anda memiliki keterbatasan untuk membayar admission
                    fee, silahkan mengisi{" "}
                    <a href="#" onClick={handlePriceDown}>
                      surat ini
                    </a>
                    . Setelah mengisi surat, silahkan tunggu sampai admin kami
                    menghubungi anda.
                  </small>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              {/* <div className="callout callout-zad-blue">
                <h5 className="text-bold text-zad-blue">
                  <i className="fa-solid fa-circle-info mr-2" />
                  Informasi Batas Pembayaran
                </h5>
                <i>Link</i> pembayaran anda aktif sampai dengan{" "}
                <b>
                  {paymentTimeLimit.toLocaleString("id-ID", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    second: "2-digit",
                    minute: "2-digit",
                    hour: "2-digit",
                    timeZoneName: "short",
                  })}
                </b>
                . Jika waktu pembayaran sudah terlewat, silahkan batalkan
                invoice lalu buat kembali invoice baru.
              </div> */}
              <div className="card">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <h4>
                          <i className="fa-solid fa-graduation-cap" /> Zad
                          Islamic Playschool
                          <small className="float-right">
                            {new Date(
                              admissionPayment.registration_fee.created_at
                            ).toLocaleString("id-ID", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </small>
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4 invoice-col">
                        Dari
                        <address>
                          <strong>Yayasan Zad Edukasi Negeri</strong>
                          <br />
                          Jl. Anggur Komplek ARCO No.C14
                          <br />
                          Kec. Bojongsari, Kota Depok
                          <br />
                          <i className="fa-solid fa-phone" /> (62) 895-1254-9319
                          <br />
                          <i className="fa-solid fa-envelope" />{" "}
                          zad.playschool@gmail.com
                        </address>
                      </div>
                      <div className="col-sm-4 invoice-col">
                        Kepada
                        <address>
                          <strong>{admissionPayment.user.name}</strong>
                          <br />
                          {/* 795 Folsom Ave, Suite 600
                          <br />
                          San Francisco, CA 94107
                          <br /> */}
                          <i className="fa-solid fa-phone" />{" "}
                          {admissionPayment.user.phone}
                          <br />
                          <i className="fa-solid fa-envelope" />{" "}
                          {admissionPayment.user.email}
                        </address>
                      </div>
                      <div className="col-sm-4 invoice-col">
                        <b>Invoice #{admissionPayment.registration_fee.id}</b>
                        <br />
                        <b>Order ID:</b>{" "}
                        {admissionPayment.registration_fee.order_id}
                        <br />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Branch/Program & Level/Jenjang</th>
                              <th>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                {admissionPayment.registration_fee.description}
                              </td>
                              <td>
                                Rp
                                {admissionPayment.registration_fee.amount.toLocaleString(
                                  "ID-id"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      <div className="col-6">
                        <p className="lead">Amount</p>
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th>Total:</th>
                                <td>
                                  Rp
                                  {admissionPayment.registration_fee.amount.toLocaleString(
                                    "ID-id"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  {/* <a
                      href="invoice-print.html"
                      rel="noopener"
                      target="_blank"
                      className="btn btn-default"
                    >
                      <i className="fas fa-print" /> Print
                    </a> */}
                  {admissionPayment.registration_fee?.status ===
                  "settlement" ? null : (
                    <>
                      <button
                        type="button"
                        className="btn bg-zad-green float-right"
                        onClick={() =>
                          window.open(
                            admissionPayment.registration_fee.redirect_url,
                            "_blank"
                          )
                        }

                        // onClick={() =>
                        //   Swal.fire({
                        //     icon: "info",
                        //     title: "Silahkan Lakukan Pembayaran",
                        //     html: "Pembayaran dapat dilakukan melalui transfer ke rekening Yayasan Zad Edukasi Negeri<br/><br/><b>📜BSI 7258909431</b>",
                        //     footer: `Setelah melakukan pembayaran, mohon melakukan konfirmasi kepada admin: <a target="blank" rel="noopener noreferrer" href="https://wa.me/6289513142813">6289513142813</a>`,
                        //   })
                        // }
                      >
                        <i className="far fa-credit-card mr-2" /> Lakukan
                        Pembayaran
                      </button>
                      <button
                        type="button"
                        className="btn bg-zad-red float-right mr-3"
                        onClick={handleDeleteInvoice}
                      >
                        <i className="fa-solid fa-xmark mr-2" /> Batalkan
                        Invoice
                      </button>
                    </>
                  )}
                  {/* <button
                      type="button"
                      className="btn btn-primary float-right"
                      style={{ marginRight: 5 }}
                    >
                      <i className="fas fa-download" /> Generate PDF
                    </button> */}
                </div>
              </div>
              {/* <div className="callout callout-zad-red">
                <h5 className="text-bold text-zad-red">
                  <i className="fa-solid fa-circle-info mr-2" />
                  Informasi Perubahan Metode Pembayaran
                </h5>
                Jika anda sudah memilih metode pembayaran, namun ingin
                menggantinya, silahkan untuk melakukan <b>
                  pembatalan invoice
                </b>{" "}
                dan melakukan <b>pembuatan invoice ulang</b>.
              </div> */}
            </div>
          </div>
        )}
      </div>
    </Template>
  );
}
