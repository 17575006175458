import { createBrowserRouter } from "react-router-dom";

import Dashboard from "../Pages/Dashboard";
import NotFound from "../Pages/NotFound";
import Login from "../Pages/Login";

import Google from "../Services/Google";

import StudentAdmission from "./Student/Admission";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  ...StudentAdmission,

  {
    path: "/auth/callback",
    element: <Google />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
