import axios from "axios";
import Swal from "sweetalert2";
import router from "../../Routes";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
    return config;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error({
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
      });
      switch (error.response.status) {
        case 401:
          localStorage.clear();
          router.navigate("/login");
          return Promise.resolve();

        case 500:
          Swal.fire({
            icon: "error",
            title: "Server Error",
            text: error.response.data?.message,
            footer:
              'Please screenshot this error message and send to <a target="blank" rel="noopener noreferrer" href="https://wa.me/6289513142813"><i className="fa-brands fa-whatsapp mr-2" />6289513142813</a>',
          });
          return Promise.resolve();

        default:
          return Promise.reject(error);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);

    return Promise.reject(error);
  }
);
