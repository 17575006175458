const baseUrl = "https://www.emsifa.com/api-wilayah-indonesia";

const getProvince = () => {
  return fetch(`${baseUrl}/api/provinces.json`).then((response) =>
    response.json()
  );
};

const getCities = (provinceId) => {
  return fetch(`${baseUrl}/api/regencies/${provinceId}.json`).then((response) =>
    response.json()
  );
};

const getSubdistricts = (regencyId) => {
  return fetch(`${baseUrl}/api/districts/${regencyId}.json`).then((response) =>
    response.json()
  );
};

const getWards = (districtId) => {
  return fetch(`${baseUrl}/api/villages/${districtId}.json`).then((response) =>
    response.json()
  );
};

module.exports = { getProvince, getCities, getSubdistricts, getWards };
