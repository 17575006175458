export default function KuotaBox(props) {
  return (
    <div
      className={`${props.color} rounded elevation-2`}
      style={{ position: "relative" }}
    >
      <div style={{ zIndex: 100 }} className="px-3 pb-2 pt-3">
        <h3 style={{ fontSize: "1.5em" }}>
          <strong>{props.count}</strong>
        </h3>
        <p style={{ fontSize: "1.25em" }}>{props.level}</p>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          color: "rgba(0,0,0,.15)",
          fontSize: "4em",
          display: "flex",
          zIndex: 0,
        }}
      >
        <i
          style={{
            marginLeft: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
          className="fa-solid fa-medal mr-3"
        />
      </div>
    </div>
  );
}
