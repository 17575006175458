import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const UserContext = createContext({
  user: {},
  setUser: () => {},
});

export default function UserContextProvider({ children }) {
  const [user, setUser] = useState({
    name: localStorage.getItem("name"),
    profilePicture: null,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken)
      axios
        .get("/user/picture", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (!response) return;

          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setUser({ ...user, profilePicture: base64 });
        });
  }, []);

  const value = {
    user,
    setUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
