import Template from "../../../Template";

import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import Loading from "../../../Components/Loading";

function responseToBase64(response) {
  return btoa(
    new Uint8Array(response).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
}

export default function Document(props) {
  const [loading, setLoading] = useState(true);
  const [unpaidError, setUnpaidError] = useState(false);

  const [birthCertificate, setBirthCertificate] = useState();
  const [familyCard, setFamilyCard] = useState();

  const [uploadFile, setUploadFile] = useState({
    birthCertificate: {
      fileName: null,
      file: null,
    },
    familyCard: {
      fileName: null,
      file: null,
    },
    commitmentLetter: {
      fileName: null,
      file: null,
    },
  });

  const [uploadLoading, setUploadLoading] = useState(false);

  const handleFileSelect = (e) => {
    let selectedFile = {
      file: e.target.files[0],
      fileName: e.target.dataset.fileName,
    };

    if (selectedFile.file) {
      let fileSizeInKB = selectedFile.file.size / 1024;

      if (fileSizeInKB <= 1024) {
        setUploadFile({
          ...uploadFile,
          [selectedFile.fileName]: {
            fileName: selectedFile.file.name,
            file: selectedFile.file,
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "The file size is more than 1 MB",
          text: "Please select another file, or you could compress the file with online file compressor tools.",
          footer: `<a target="blank" rel="noopener noreferrer" href='https://www.iloveimg.com/compress-image'>Compress IMAGE (iloveimg.com/compress-image)</a>`,
        });
      }
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    setUploadLoading(true);

    const formData = new FormData();

    formData.append(
      e.target.dataset.uploadKey,
      uploadFile[e.target.dataset.fileName].file
    );

    axios
      .post("/admission/document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "File has been uploaded.",
        });

        if (e.target.dataset.uploadKey === "birth_certificate")
          getBirthCertificateFile();
        else if (e.target.dataset.uploadKey === "family_card")
          getFamilyCardFile();
      })
      .finally(() => setUploadLoading(false));
  };

  const getBirthCertificateFile = () => {
    axios
      .get("/admission/document", {
        params: {
          name: "birth_certificate",
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        setBirthCertificate(responseToBase64(response.data));
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 402)
          setUnpaidError(true);
      });
  };

  const getFamilyCardFile = () => {
    return axios
      .get("/admission/document", {
        params: {
          name: "family_card",
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        setFamilyCard(responseToBase64(response.data));
      })
      .catch(() => {});
  };

  const getCommitmentLetter = () => {
    return axios
      .get("/admission/document", {
        params: {
          name: "commitment_letter",
        },
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, "Commitment Letter.pdf");
      })
      .catch((error) => {
        if (error.response.status === 404)
          Swal.fire({
            title: "Commitment Letter Not Found!",
            icon: "error",
            text: "You have not uploaded the commitment letter.",
          });
      });
  };

  useEffect(() => {
    const getBirthCertificateFilePromise = getBirthCertificateFile();
    const getFamilyCardFilePromise = getFamilyCardFile();

    Promise.allSettled([
      getBirthCertificateFilePromise,
      getFamilyCardFilePromise,
    ]).finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Template title="Document Submit">
        <Loading />
      </Template>
    );

  if (unpaidError)
    return (
      <Template title="Document Submit">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header bg-zad-red">
                  <h3 className="card-title">Informasi</h3>
                </div>
                <div className="card-body">
                  <h3
                    className="text-center text-zad-red"
                    style={{ fontSize: "2.5em" }}
                  >
                    <i className="fa-solid fa-circle-exclamation" />
                  </h3>
                  <h4 className="text-center text-bold">
                    Anda belum melakukan pembayaran
                  </h4>
                  <h5 className="text-center">
                    Silahkan lakukan pembayaran untuk melanjutkan
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
    );

  return (
    <Template title="Document Submit">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header bg-zad-green">
                <h3 className="card-title">
                  <i className="fa-solid fa-folder-open mr-2" />
                  Upload Form
                </h3>
              </div>
              <form className="form-horizontal">
                <div className="card-body">
                  <h4>
                    Silahkan unggah berkas-berkas yang diperlukan pada menu di
                    bawah.
                  </h4>
                  <p className="text-muted">
                    <i>Download</i> format surat komitmen{" "}
                    <a
                      href="https://drive.google.com/file/d/1dLopanKiCntKOJFTWW91IDEbTZe1ywWT/view?usp=drive_link"
                      rel="noreferrer"
                      target="_blank"
                    >
                      di sini.
                    </a>
                  </p>
                  <div className="form-group row mt-3">
                    <label
                      htmlFor="input-file-akta-kelahiran"
                      className="col-sm-2 col-form-label"
                    >
                      Akta Kelahiran
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="input-file-akta-kelahiran"
                            onChange={handleFileSelect}
                            accept="image/jpeg,image/png"
                            data-file-name="birthCertificate"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="input-file-akta-kelahiran"
                          >
                            {uploadFile.birthCertificate.fileName
                              ? uploadFile.birthCertificate.fileName
                              : "Choose File"}
                          </label>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm float-right ml-2"
                          data-file-name="birthCertificate"
                          data-upload-key="birth_certificate"
                          onClick={handleFileUpload}
                          disabled={
                            !uploadFile.birthCertificate.fileName ||
                            uploadLoading
                          }
                        >
                          <i className="fa-solid fa-upload mr-2" />
                          Upload
                        </button>
                      </div>

                      <small className="text-muted">
                        Format berkas <strong>jpg</strong>,{" "}
                        <strong>jpeg</strong> atau <strong>png</strong> dengan
                        ukuran maksimum <strong>1 MB</strong>.
                      </small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="input-file-kk"
                      className="col-sm-2 col-form-label"
                    >
                      Kartu Keluarga
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="input-file-kk"
                            onChange={handleFileSelect}
                            accept="image/jpeg,image/png"
                            data-file-name="familyCard"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="input-file-kk"
                          >
                            {uploadFile.familyCard.fileName
                              ? uploadFile.familyCard.fileName
                              : "Choose File"}
                          </label>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm float-right ml-2"
                          data-file-name="familyCard"
                          data-upload-key="family_card"
                          onClick={handleFileUpload}
                          disabled={
                            !uploadFile.familyCard.fileName || uploadLoading
                          }
                        >
                          <i className="fa-solid fa-upload mr-2" />
                          Upload
                        </button>
                      </div>

                      <small className="text-muted">
                        Format berkas <strong>jpg</strong>,{" "}
                        <strong>jpeg</strong> atau <strong>png</strong> dengan
                        ukuran maksimum <strong>1 MB</strong>.
                      </small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Surat Komitmen
                    </label>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="input-file-surat-komitmen"
                            onChange={handleFileSelect}
                            accept="application/pdf"
                            data-file-name="commitmentLetter"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="input-file-surat-komitmen"
                          >
                            {uploadFile.commitmentLetter.fileName
                              ? uploadFile.commitmentLetter.fileName
                              : "Choose File"}
                          </label>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm float-right ml-2"
                          data-file-name="commitmentLetter"
                          data-upload-key="commitment_letter"
                          onClick={handleFileUpload}
                          disabled={
                            !uploadFile.commitmentLetter.fileName ||
                            uploadLoading
                          }
                        >
                          <i className="fa-solid fa-upload mr-2" />
                          Upload
                        </button>
                      </div>
                      <small className="text-muted">
                        <b>Khusus online class</b>. Format berkas{" "}
                        <strong>pdf</strong> dengan ukuran maksimum{" "}
                        <strong>1 MB</strong>.
                      </small>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-header bg-zad-red">
                <h3 className="card-title">
                  <i className="fa-solid fa-file-lines mr-2" />
                  Akta Kelahiran
                </h3>
              </div>
              <div className="card-body p-0">
                {birthCertificate ? (
                  <img
                    className="w-100"
                    src={`data:;base64,${birthCertificate}`}
                    alt="Akta Kelahiran"
                  />
                ) : (
                  <p className="ml-4 my-3">
                    <i className="fa-solid fa-circle-exclamation text-danger mr-1" />
                    Please upload file
                  </p>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header bg-zad-red">
                <h3 className="card-title">
                  <i className="fa-solid fa-file-lines mr-2" />
                  Kartu Keluarga
                </h3>
              </div>
              <div className="card-body p-0">
                {familyCard ? (
                  <img
                    className="w-100"
                    src={`data:;base64,${familyCard}`}
                    alt="Kartu Keluarga"
                  />
                ) : (
                  <p className="ml-4 my-3">
                    <i className="fa-solid fa-circle-exclamation text-danger mr-1" />
                    Please upload file
                  </p>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header bg-zad-red">
                <h3 className="card-title">
                  <i className="fa-solid fa-file-lines mr-2" />
                  Surat Komitmen (Khusus Online Class)
                </h3>
              </div>
              <div className="card-body">
                <button
                  className="btn btn-primary"
                  onClick={getCommitmentLetter}
                >
                  <i className="fa-solid fa-download mr-2" /> View Uploaded File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
