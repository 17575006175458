import { Navigate } from "react-router-dom";
import Template from "../../Template";

export default function Dashboard() {
  return <Navigate to="/student/admission/dashboard" replace={true} />;

  // return (
  //   <Template page="dashboard">
  //     <div className="container-fluid">
  //       <div className="row">
  //         <div className="col">
  //           <div className="card card-primary">
  //             <div className="card-header">
  //               <h3 className="card-title">Pemberitahuan</h3>
  //             </div>
  //             <div className="card-body">
  //               <p>Dasbor masih dalam tahap pengembangan</p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </Template>
  // );
}
