import { useEffect, useState } from "react";
import Template from "../../../Template";
import Loading from "../../../Components/Loading";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

import {
  getProvince,
  getCities,
  getSubdistricts,
  getWards,
} from "../../../Services/DataWilayahIndonesia";

import User from "../../../Assets/user.png";

import Cities from "../../../Assets/Data/Cities.json";

export default function Profile() {
  const [loading, setLoading] = useState(true);
  const [profilePictureLoading, setProfilePictureLoading] = useState(true);
  const [admissionFeePaid, setAdmissionFeePaid] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [wards, setWards] = useState([]);

  const [user, setUser] = useState({
    name: "",
    gender: 0,
    hometown: "",
    birth: "2001-1-1",
    phone: 0,
    country: "Indonesia",
    province: "",
    city: "",
    subdistrict: "",
    ward: "",
    address: "",
    student: {
      branch: "",
      level: "",
      father_name: "",
      mother_name: "",
    },
  });

  const [customHometown, setCustomHometown] = useState("");

  const [profilePicture, setProfilePicture] = useState(null);
  const [uploadProfilePictureFileName, setUploadProfilePictureFileName] =
    useState();
  const [uploadProfilePicture, setUploadProfilePicture] = useState();
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    getProvince().then((provinces) => setProvinces(provinces));

    const getMyProfilePromise = axios.get("/auth/me").then((response) => {
      let user = response.data;

      if (user.province)
        getCities(user.province).then((cities) => setCities(cities));

      if (user.city)
        getSubdistricts(user.city).then((subdistricts) =>
          setSubdistricts(subdistricts)
        );

      if (user.subdistrict)
        getWards(user.subdistrict).then((wards) => setWards(wards));

      if (user.hometown)
        if (Cities.indexOf(user.hometown) === -1) {
          setCustomHometown(user.hometown);
          user.hometown = "LAINNYA/OTHER";
        }

      setUser(user);
    });

    const getMyAdmissionFeePaymentStatusPromise = axios
      .get("/admission/payment")
      .then((response) => {
        console.log(response.data);
      })
      .then((response) => {
        if (response.data.registration_fee?.status === "settlement")
          setAdmissionFeePaid(true);
      })
      .catch(() => {});

    Promise.allSettled([
      getMyProfilePromise,
      getMyAdmissionFeePaymentStatusPromise,
    ]).finally(() => {
      setLoading(false);
    });

    getProfilePicture();
  }, []);

  const getProfilePicture = () => {
    axios
      .get("/user/picture", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setProfilePicture(base64);
      })
      .finally(() => setProfilePictureLoading(false));
  };

  const handleSave = () => {
    let data = {
      name: user.name,
      phone: user.phone,
      gender: user.gender,
      hometown:
        user.hometown !== "LAINNYA/OTHER" ? user.hometown : customHometown,
      birth: moment(new Date(user.birth)).format("YYYY-MM-DD"),
      country: user.country,
      province: user.province,
      city: user.city,
      subdistrict: user.subdistrict,
      ward: user.ward,
      address: user.address,
    };

    let validationError = false;
    Object.values(data).map((value) => {
      if (
        value === null ||
        value === undefined ||
        value === "" ||
        value === "1970-01-01"
      )
        validationError = true;
      return false;
    });

    if (validationError) {
      console.table(data);
      setValidationError(true);
      Swal.fire({
        icon: "warning",
        title: "Please fill all the specified field.",
        text: "If you ecounter a problem when selecting a dropdown item, please select another item and switch back to your desired item value.",
      });
      return;
    }

    let updateUserPromise = axios.patch("/user", {
      name: user.name,
      phone: user.phone,
      gender: user.gender,
      hometown:
        user.hometown !== "LAINNYA/OTHER" ? user.hometown : customHometown,
      birth: moment(new Date(user.birth)).format("YYYY-MM-DD"),
      country: user.country,
      province: user.province,
      city: user.city,
      subdistrict: user.subdistrict,
      ward: user.ward,
      address: user.address,
    });

    let promises = [updateUserPromise];

    if (!admissionFeePaid) {
      promises.push(
        axios
          .post("/student/me", {
            father_name: user.student.father_name,
            mother_name: user.student.mother_name,
            branch: user.student.branch,
            level: user.student.level,
          })
          .catch((error) => {
            if (error.response.status === 409) {
              axios.patch("/student/me", {
                father_name: user.student.father_name,
                mother_name: user.student.mother_name,
                branch: user.student.branch,
                level: user.student.level,
              });
            }
          })
      );
    }

    Promise.allSettled(promises).finally(() =>
      Swal.fire({
        title: "Your profile has been saved successfully.",
        icon: "success",
        footer:
          "Please refresh the page if your photo/name on the sidebar doesn't change",
      })
    );
  };

  const handleFileSelect = (e) => {
    let file = e.target.files[0];

    if (file) {
      let fileSizeInKB = e.target.files[0].size / 1024;

      if (fileSizeInKB <= 500) {
        setUploadProfilePictureFileName(e.target.files[0].name);
        setUploadProfilePicture(e.target.files[0]);
      } else {
        Swal.fire({
          icon: "error",
          title: "The file size is more than 500 KB",
          text: "Please select another file, or you could compress the file with online file compressor tools.",
          footer: `<a target="blank" rel="noopener noreferrer" href='https://www.iloveimg.com/compress-image'>Compress IMAGE (iloveimg.com/compress-image)</a>`,
        });
      }
    }
  };

  const handleProfilePictureUpload = () => {
    const formData = new FormData();

    formData.append("profile_picture", uploadProfilePicture);
    axios
      .post("/user/picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Your profile picture has been updated",
          footer:
            "Please refresh the page if your photo/name on the sidebar doesn't change",
        });

        getProfilePicture();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          Swal.fire({
            icon: "error",
            title: "The file size is more than 500 KB",
          });
        }
      });
  };

  const checkValidationError = (data) => {
    if (
      (data === null ||
        data === undefined ||
        data === "" ||
        data === "1970-01-01") &&
      validationError
    )
      return true;
    return false;
  };

  if (loading)
    return (
      <Template title="Student Profile">
        <Loading />
      </Template>
    );

  return (
    <Template title="Student Profile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="callout callout-zad-blue">
              <p>
                <i className="fa-solid fa-circle-info mr-2" />
                Perubahan student profile akan <b>menghapus</b> isi assessment
                form yang telah diisi.
              </p>
            </div>

            <div className="card">
              <div className="card-header bg-zad-green">
                <h3 className="card-title">
                  <i className="fa-solid fa-school-flag mr-2" />
                  School Branch
                </h3>
              </div>

              <div className="card-body">
                <div className="form-group">
                  <label>Branch/Program</label>
                  <select
                    className={`form-control ${
                      checkValidationError(user.student?.branch)
                        ? "is-invalid"
                        : null
                    }`}
                    value={user.student?.branch}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        student: { ...user.student, branch: e.target.value },
                      })
                    }
                    disabled={admissionFeePaid}
                  >
                    <option value="">Choose Branch...</option>
                    <option value="ARCO">ARCO</option>
                    <option value="TANAH BARU">TANAH BARU</option>
                    <option value="ONLINE CLASS">ONLINE CLASS</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>Level/Jenjang</label>
                  <select
                    className={`form-control ${
                      checkValidationError(user.student?.level)
                        ? "is-invalid"
                        : null
                    }`}
                    value={user.student?.level}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        student: { ...user.student, level: e.target.value },
                      })
                    }
                    disabled={admissionFeePaid}
                  >
                    <option value="">Choose Level...</option>
                    <option value="KB">KB (minimal umur 3 tahun)</option>
                    <option value="TKA">TKA (minimal umur 4 tahun)</option>
                    <option value="TKB">TKB (minimal umur 5 tahun)</option>
                  </select>
                  <small className="text-muted">
                    Umur minimal dihitung per 1 Juli 2024
                  </small>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header bg-zad-red">
                <h3 className="card-title">
                  <i className="fa-solid fa-id-card mr-2" />
                  Personal Information
                </h3>
              </div>

              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="input-student-name">Student Name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      checkValidationError(user.name) ? "is-invalid" : null
                    }`}
                    id="input-student-name"
                    placeholder="Enter student name"
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-student-email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="input-student-email"
                    placeholder="Enter email"
                    defaultValue={user.email}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label>Gender</label>
                  <select
                    className={`form-control ${
                      checkValidationError(user.gender) ? "is-invalid" : null
                    }`}
                    value={user.gender}
                    onChange={(e) =>
                      setUser({ ...user, gender: e.target.value })
                    }
                  >
                    <option value="">Choose Gender...</option>
                    <option value={1}>Laki-Laki/Male/Banin</option>
                    <option value={0}>Perempuan/Female/Banat</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="input-other-hometown">
                    Hometown/Kota Kelahiran
                  </label>
                  <select
                    className={`form-control ${
                      checkValidationError(user.hometown) ? "is-invalid" : null
                    }`}
                    onChange={(e) =>
                      setUser({ ...user, hometown: e.target.value })
                    }
                    value={user.hometown}
                  >
                    <option value="">Choose Hometown...</option>
                    {Cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                    <option value="LAINNYA/OTHER">LAINNYA/OTHER</option>
                  </select>

                  {user.hometown === "LAINNYA/OTHER" ? (
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="input-other-hometown"
                      placeholder="Enter other hometown here..."
                      value={customHometown}
                      onChange={(e) =>
                        setCustomHometown(e.target.value.toUpperCase())
                      }
                    />
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="w-100">Tanggal Lahir</label>
                  <DatePicker
                    className={`form-control ${
                      checkValidationError(user.birth) ? "is-invalid" : null
                    }`}
                    selected={new Date(user.birth)}
                    onChange={(date) => setUser({ ...user, birth: date })}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="d MMMM yyyy"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-student-father-name">
                    Nama Lengkap Ayah
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      checkValidationError(user.student?.father_name)
                        ? "is-invalid"
                        : null
                    }`}
                    id="input-student-father-name"
                    placeholder="Enter father name"
                    value={user.student?.father_name}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        student: {
                          ...user.student,
                          father_name: e.target.value,
                        },
                      })
                    }
                    disabled={admissionFeePaid}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-student-mother-name">
                    Nama Lengkap Ibu
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      checkValidationError(user.student?.mother_name)
                        ? "is-invalid"
                        : null
                    }`}
                    id="input-student-mother-name"
                    placeholder="Enter mother name"
                    value={user.student?.mother_name}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        student: {
                          ...user.student,
                          mother_name: e.target.value,
                        },
                      })
                    }
                    disabled={admissionFeePaid}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="input-student-phone">No WhatsApp Ibu</label>
                  <input
                    type="number"
                    className={`form-control ${
                      checkValidationError(user.phone) ? "is-invalid" : null
                    }`}
                    id="input-student-phone"
                    placeholder="Enter phone number started with 628..."
                    value={user.phone}
                    onChange={(e) =>
                      setUser({ ...user, phone: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header bg-zad-blue">
                <h3 className="card-title">
                  <i className="fa-solid fa-id-card-clip mr-2" />
                  Profile Picture
                </h3>
              </div>

              <div className="card-body">
                <div className="callout callout-zad-green">
                  <p>
                    <i className="fa-solid fa-circle-info mr-2" />
                    Foto ini akan digunakan untuk ID card fisik dan database Zad
                    Islamic Playschool. Jika sudah ada mohon di upload sekarang,
                    jika belum bisa menyusul paling lambat tanggal 31 Mei 2024.
                  </p>
                </div>

                {!profilePictureLoading ? (
                  <img
                    src={
                      profilePicture ? `data:;base64,${profilePicture}` : User
                    }
                    className="img-circle elevation-2 d-flex m-auto"
                    alt="User"
                    height={200}
                  />
                ) : (
                  <Loading />
                )}

                <div className="form-group mt-3">
                  <label>Upload Profile Picture</label>
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="user-profile-picture"
                        onChange={handleFileSelect}
                        accept="image/jpeg,image/png"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="user-profile-picture"
                      >
                        {uploadProfilePictureFileName
                          ? uploadProfilePictureFileName
                          : "Choose File"}
                      </label>
                    </div>
                  </div>
                  <small className="text-muted">
                    Format berkas <strong>jpg</strong> atau <strong>png</strong>{" "}
                    dengan ukuran maksimum <strong>500 KB</strong> per berkas.
                  </small>
                </div>
              </div>

              <div className="card-footer">
                <button
                  className="btn btn-primary float-right px-4"
                  onClick={handleProfilePictureUpload}
                  disabled={uploadProfilePicture === null}
                >
                  <i className="fa-solid fa-upload mr-2" />
                  Upload
                </button>
              </div>
            </div>

            <div className="card">
              <div className="card-header bg-zad-red">
                <h3 className="card-title">
                  <i className="fa-solid fa-house-chimney mr-2" />
                  Address
                </h3>
              </div>

              <div className="card-body">
                <div className="callout callout-zad-green">
                  <p>
                    <i className="fa-solid fa-circle-info mr-2" />
                    Alamat di bawah ini akan menjadi alamat penerima learning
                    kit untuk <i>Online Class</i> yang kemudian akan dikirim
                    melalui ekspedisi!
                  </p>
                </div>

                <div className="form-group">
                  <label>Country</label>
                  <select
                    className={`form-control ${
                      checkValidationError(user.country) ? "is-invalid" : null
                    }`}
                    onChange={(e) =>
                      setUser({ ...user, country: e.target.value })
                    }
                    value={user.country}
                  >
                    <option value="">Choose Country...</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Other/Lainnya">Other/Lainnya</option>
                  </select>
                </div>
                {user.country === "Indonesia" ? (
                  <>
                    <div className="form-group">
                      <label>Province</label>
                      <select
                        className={`form-control ${
                          checkValidationError(user.province) &&
                          user.country === "Indonesia"
                            ? "is-invalid"
                            : null
                        }`}
                        onChange={(e) => {
                          getCities(e.target.value).then((cities) =>
                            setCities(cities)
                          );
                          setUser({ ...user, province: e.target.value });
                        }}
                        value={user.province}
                      >
                        <option value="">Choose Province...</option>
                        {provinces.map((province) => (
                          <option key={province.id} value={province.id}>
                            {province.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <select
                        className={`form-control ${
                          checkValidationError(user.city) &&
                          user.country === "Indonesia"
                            ? "is-invalid"
                            : null
                        }`}
                        onChange={(e) => {
                          getSubdistricts(e.target.value).then((subdistricts) =>
                            setSubdistricts(subdistricts)
                          );
                          setUser({ ...user, city: e.target.value });
                        }}
                        disabled={cities.length === 0 ? true : false}
                        value={user.city}
                      >
                        <option value="">Choose City...</option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Subdistrict/Kecamatan</label>
                      <select
                        className={`form-control ${
                          checkValidationError(user.subdistrict) &&
                          user.country === "Indonesia"
                            ? "is-invalid"
                            : null
                        }`}
                        onChange={(e) => {
                          getWards(e.target.value).then((wards) =>
                            setWards(wards)
                          );
                          setUser({ ...user, subdistrict: e.target.value });
                        }}
                        disabled={subdistricts.length === 0 ? true : false}
                        value={user.subdistrict}
                      >
                        <option value="">
                          Choose Subdistrict/Kecamatan...
                        </option>
                        {subdistricts.map((subdistrict) => (
                          <option key={subdistrict.id} value={subdistrict.id}>
                            {subdistrict.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Ward/Kelurahan</label>
                      <select
                        className={`form-control ${
                          checkValidationError(user.ward) &&
                          user.country === "Indonesia"
                            ? "is-invalid"
                            : null
                        }`}
                        disabled={wards.length === 0 ? true : false}
                        value={user.ward}
                        onChange={(e) => {
                          setUser({ ...user, ward: e.target.value });
                        }}
                      >
                        <option value="">Choose Ward/Kelurahan...</option>
                        {wards.map((ward) => (
                          <option key={ward.id} value={ward.id}>
                            {ward.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : null}
                <div className="form-group">
                  <label>Address</label>
                  <textarea
                    className={`form-control ${
                      checkValidationError(user.address) ? "is-invalid" : null
                    }`}
                    rows={5}
                    placeholder="Enter your full address here..."
                    value={user.address}
                    onChange={(e) =>
                      setUser({ ...user, address: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-auto mb-2">
            <button className="btn bg-zad-green px-5 py-2" onClick={handleSave}>
              <i className="fa-solid fa-floppy-disk mr-2" />
              Save
            </button>
          </div>
        </div>
      </div>
    </Template>
  );
}
