import Template from "../../Template";

export default function NotFound() {
  return (
    <Template>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1 className="text-center">404</h1>
            <h4 className="text-center">Not Found</h4>
          </div>
        </div>
      </div>
    </Template>
  );
}
