import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function Header() {
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (
      localStorage.getItem("access_token") == null ||
      localStorage.getItem("name") == null
    )
      setRedirect("/login");
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    setRedirect("/login");
  };

  if (redirect != null) return <Navigate to={redirect} replace={true} />;

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="/" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="https://www.zadulilmi.id" className="nav-link">
            zadulilmi.id
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="#" onClick={handleLogout} className="nav-link">
            Logout
          </a>
        </li>
      </ul>
    </nav>
  );
}
