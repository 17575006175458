import Template from "../../../Template";

import Loading from "../../../Components/Loading";
import AdmissionProgress from "../../../Components/AdmissionProgress";
import { useEffect, useState } from "react";
import axios from "axios";

import PanduanPpdbPdf from "../../../Assets/Admission 24-25 Zad Islamic Playschool.pdf";
import QuotaBox from "../../../Components/QuotaBox";

export default function Dashboard(props) {
  const [loading, setLoading] = useState(true);

  const [admissionProgressData, setAdmissionProgressData] = useState();
  const [quota, setQuota] = useState({});

  useEffect(() => {
    axios
      .get("/admission/progress")
      .then((response) => {
        setAdmissionProgressData(response.data);
        setQuota(response.data.quota);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading)
    return (
      <Template title="Admission Dashboard">
        <Loading />
      </Template>
    );

  return (
    <Template title="Admission Dashboard">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <AdmissionProgress data={admissionProgressData} />
          </div>

          <div className="col-md-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h4>
                    <i className="fa-solid fa-school mr-2" />
                    Sisa Kuota{" "}
                    <i>
                      <b>Reguler Class</b>
                    </i>{" "}
                    Cabang <b>Tanah Baru</b>
                  </h4>
                  <hr />
                </div>
                <div className="col">
                  <QuotaBox
                    level="KB"
                    // count={
                    //   quota["TANAH BARU"].KB === 0
                    //     ? "Waiting List"
                    //     : quota["TANAH BARU"].KB
                    // }
                    count={
                      quota["TANAH BARU"].KB === 0
                        ? "Waiting List"
                        : "Kuota Terbatas"
                    }
                    color="bg-zad-green"
                  />
                </div>
                <div className="col">
                  <QuotaBox
                    level="TKA"
                    count={quota["TANAH BARU"].TKA}
                    color="bg-zad-red"
                  />
                </div>
                <div className="col">
                  <QuotaBox
                    level="TKB"
                    // count={
                    //   quota["TANAH BARU"].TKB === 0
                    //     ? "Waiting List"
                    //     : quota["TANAH BARU"].TKB
                    // }
                    count={
                      quota["TANAH BARU"].TKB === 0
                        ? "Waiting List"
                        : "Kuota Terbatas"
                    }
                    color="bg-zad-blue"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12">
                  <h4>
                    <i className="fa-solid fa-school mr-2" />
                    Sisa Kuota{" "}
                    <i>
                      <b>Reguler Class</b>
                    </i>{" "}
                    Cabang <b>ARCO</b>
                  </h4>
                  <hr />
                </div>
                <div className="col">
                  <QuotaBox
                    level="KB"
                    // count={quota.ARCO.KB === 0 ? "Waiting List" : quota.ARCO.KB}
                    count={
                      quota.ARCO.KB === 0 ? "Waiting List" : "Kuota Terbatas"
                    }
                    color="bg-zad-green"
                  />
                </div>
                <div className="col">
                  <QuotaBox
                    level="TKA"
                    count={quota.ARCO.TKA}
                    color="bg-zad-red"
                  />
                </div>
                <div className="col">
                  <QuotaBox
                    level="TKB"
                    // count={
                    //   quota.ARCO.TKB === 0 ? "Waiting List" : quota.ARCO.TKB
                    // }
                    count={
                      quota.ARCO.TKB === 0 ? "Waiting List" : "Kuota Terbatas"
                    }
                    color="bg-zad-blue"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12">
                  <h4>
                    <i className="fa-solid fa-house-laptop mr-2" />
                    Kuota{" "}
                    <i>
                      <b>Online Class</b> (Home based Education)
                    </i>
                  </h4>
                  <hr />
                </div>
                <div className="col-xs-12 col-xl-4 mb-3">
                  <QuotaBox
                    level="KB"
                    count={
                      quota["ONLINE CLASS"].KB > 0
                        ? "Available"
                        : "Not Available"
                    }
                    color="bg-zad-green"
                  />
                </div>
                <div className="col-xs-12 col-xl-4 mb-3">
                  <QuotaBox
                    level="TKA"
                    count={
                      quota["ONLINE CLASS"].TKA > 0
                        ? "Available"
                        : "Not Available"
                    }
                    color="bg-zad-red"
                  />
                </div>
                <div className="col-xs-12 col-xl-4 mb-3">
                  <QuotaBox
                    level="TKB"
                    count={
                      quota["ONLINE CLASS"].TKB > 0
                        ? "Available"
                        : "Not Available"
                    }
                    color="bg-zad-blue"
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="callout callout-zad-green">
                  <h5 style={{ fontSize: "1.1em", fontWeight: 600 }}>
                    <i className="fa-solid fa-circle-info mr-2" />
                    Informasi Waiting List
                  </h5>
                  <p>
                    Anda tetap bisa melakukan pendaftaran pada jenjang yang
                    dituju hingga proses pengisian Assessment Form. Untuk
                    pembayaran akan menunggu diinfokan oleh admin{" "}
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/6289513142813"
                    >
                      <i className="fa-brands fa-whatsapp mr-2" />
                      6289513142813
                    </a>{" "}
                    apabila dipastikan memperoleh kuota.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            {/* <div className="timeline">
              <div className="time-label">
                <span className="bg-red">10 Feb. 2014</span>
              </div>
              <div>
                <i className="fas fa-envelope bg-blue" />
                <div className="timeline-item">
                  <span className="time">
                    <i className="fas fa-clock" /> 12:05
                  </span>
                  <h3 className="timeline-header">
                    <a href="#">Support Team</a> sent you an email
                  </h3>
                  <div className="timeline-body">
                    Etsy doostang zoodles disqus groupon greplin oooj voxy
                    zoodles, weebly ning heekya handango imeem plugg dopplr
                    jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                    Babblely odeo kaboodle quora plaxo ideeli hulu weebly
                    balihoo...
                  </div>
                  <div className="timeline-footer">
                    <a className="btn btn-primary btn-sm">Read more</a>
                    <a className="btn btn-danger btn-sm">Delete</a>
                  </div>
                </div>
              </div>
              <div>
                <i className="fas fa-user bg-green" />
                <div className="timeline-item">
                  <span className="time">
                    <i className="fas fa-clock" /> 5 mins ago
                  </span>
                  <h3 className="timeline-header no-border">
                    <a href="#">Sarah Young</a> accepted your friend request
                  </h3>
                </div>
              </div>
              <div>
                <i className="fas fa-comments bg-yellow" />
                <div className="timeline-item">
                  <span className="time">
                    <i className="fas fa-clock" /> 27 mins ago
                  </span>
                  <h3 className="timeline-header">
                    <a href="#">Jay White</a> commented on your post
                  </h3>
                  <div className="timeline-body">
                    Take me to your leader! Switzerland is small and neutral! We
                    are more like Germany, ambitious and misunderstood!
                  </div>
                  <div className="timeline-footer">
                    <a className="btn btn-warning btn-sm">View comment</a>
                  </div>
                </div>
              </div>
              <div className="time-label">
                <span className="bg-green">3 Jan. 2014</span>
              </div>
              <div>
                <i className="fa fa-camera bg-purple" />
                <div className="timeline-item">
                  <span className="time">
                    <i className="fas fa-clock" /> 2 days ago
                  </span>
                  <h3 className="timeline-header">
                    <a href="#">Mina Lee</a> uploaded new photos
                  </h3>
                  <div className="timeline-body">
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                    <img src="https://placehold.it/150x100" alt="..." />
                  </div>
                </div>
              </div>
              <div>
                <i className="fas fa-video bg-maroon" />
                <div className="timeline-item">
                  <span className="time">
                    <i className="fas fa-clock" /> 5 days ago
                  </span>
                  <h3 className="timeline-header">
                    <a href="#">Mr. Doe</a> shared a video
                  </h3>
                  <div className="timeline-body">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/tMWkeBIohBs"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="timeline-footer">
                    <a href="#" className="btn btn-sm bg-maroon">
                      See comments
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <i className="fas fa-clock bg-gray" />
              </div>
            </div> */}
            <div className="card">
              <div className="card-header bg-zad-blue">
                <h3 className="card-title">
                  <i className="fa-solid fa-circle-question mr-2" />
                  Admission Ebook 24/25
                </h3>
              </div>
              <div className="card-body p-0">
                <iframe
                  title="Admission E-Book 24/25"
                  height={700}
                  className="w-100"
                  src={PanduanPpdbPdf}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div className="card-header bg-zad-blue">
                <h3 className="card-title">
                  <i className="fa-brands fa-whatsapp mr-2" />
                  Narahubung Bantuan PPDB
                </h3>
              </div>
              <div className="card-body">
                <p>
                  Jika anda mengalami kesulitan, silahkan hubungi admin kami di
                  <a
                    href="https://wa.me/6289513142813"
                    target="_blank"
                    rel="noreferrer nofollow"
                    className="ml-1"
                  >
                    <i className="fa-brands fa-whatsapp" /> 6289513142813
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
