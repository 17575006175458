import StudentAdmissionAssessment from "../../../Pages/StudentAdmission/Assessment";
import StudentAdmissionPayment from "../../../Pages/StudentAdmission/Payment";
import StudentAdmissionDashboard from "../../../Pages/StudentAdmission/Dashboard";
import StudentAdmissionProfile from "../../../Pages/StudentAdmission/Profile";
import StudentAdmissionDocument from "../../../Pages/StudentAdmission/Document";
import StudentAdmissionClass from "../../../Pages/StudentAdmission/Class";
import StudentAdmissionInterview from "../../../Pages/StudentAdmission/Interview";

const StudentAdmission = [
  {
    path: "/student/admission/dashboard",
    element: <StudentAdmissionDashboard />,
  },
  {
    path: "/student/admission/assessment",
    element: <StudentAdmissionAssessment />,
  },
  {
    path: "/student/admission/payment",
    element: <StudentAdmissionPayment />,
  },
  {
    path: "/student/admission/profile",
    element: <StudentAdmissionProfile />,
  },
  {
    path: "/student/admission/document",
    element: <StudentAdmissionDocument />,
  },
  {
    path: "/student/admission/interview",
    element: <StudentAdmissionInterview />,
  },
  {
    path: "/student/admission/class",
    element: <StudentAdmissionClass />,
  },
];

export default StudentAdmission;
